import * as React from "react"
import { Link, graphql } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Container, Row, Col } from "react-bootstrap"

import homeIcon from "../images/home_circle_icon.png"
import carCharger from "../images/electric_pump.png"
import homeAutomation from "../images/iphone.png"
import lightBulbIcon from "../images/lightbulb.png"

import { getImage } from "gatsby-plugin-image"
import { BgImage } from 'gbimage-bridge';

const IndexPage = ({data}) => (
  <Layout>
    <Seo title="Marin County Electrician Thomas Tripolt" />
    <Container className="py-4">
      <Row>
        <Col lg={6} className="text-center py-4">
          <img src={homeIcon} alt="home icon" className="img-fluid" />
          <h2><small>Residential & Commercial</small></h2>
          <p>Thomas’s Tripolt’s close attention to detail and depth of experience ensures you will receive the highest quality work on every electrical project.</p>
          <p>
            <Link to="/services/residential-electrical-services/" className="btn btn-outline-dark">Learn More</Link>
          </p>
        </Col>
        <Col lg={6} className="text-center py-4">
          <img src={carCharger} alt="electric gas icon" className="img-fluid" />
          <h2><small>Car Charging Stations Installation</small></h2>
          <p>Thomas’s Tripolt’s close attention to detail and depth of experience ensures you will receive the highest quality work on every electrical project.</p>
          <p>
            <Link to="/services/car-charging-station-installation/" className="btn btn-outline-dark">Learn More</Link>
          </p>
        </Col>
        <Col lg={6} className="text-center py-4">
          <img src={homeAutomation} alt="iphone logo" className="img-fluid" />
          <h2><small>Home Automation</small></h2>
          <p>Convert to an energy efficient, automated home, with voice activation everywhere you go from your smart phone or tablet.</p>
          <p>
            <Link to="/services/home-automation-installation/" className="btn btn-outline-dark">Learn More</Link>
          </p>
        </Col>
        <Col lg={6} className="text-center py-4">
          <img src={lightBulbIcon} alt="light bulb logo" className="img-fluid" />
          <h2><small>Energy Efficiency</small></h2>
          <p>LED lighting, energy metering, dimmers and motorized window shades – All Electric can do whatever you need.</p>
          <p>
            <Link to="/services/energy-efficiency-installation/" className="btn btn-outline-dark">Learn More</Link>
          </p>
        </Col>
      </Row>
    </Container>
    <Container>
      {/* <GatsbyImage image={getImage(data.light)} alt="The Desert" /> */}
    <BgImage image={getImage(data.light)} className="subhead mb-3">
      <div className="color-overlay px-xs-0 px-sm-5 d-flex pt-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <Row>
                <Col lg={8}>
                  <h2>About All Electric</h2>
                  <p>Master electrician and craftsman Thomas Tripolt of All Electric has served the Marin community since 1984 with integrity, honesty and close attention to every detail.</p>
                </Col>
                <Col lg={4} className="d-flex justify-content-center align-items-center">
                  <p>
                    <Link to="/services/energy-efficiency-installation/" className="btn btn-light">Learn More</Link>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          </div>
          </BgImage>
    </Container>
    <Container className="text-center">
      <h2>“ELECTRICITY IS REALLY JUST ORGANIZED LIGHTNING.”</h2>
      <p>– GEORGE CARLIN</p>
    </Container>
    <Container>
      <hr />
      <p className="text-center">Client Testimonials</p>
      <div className="testimonial-quote group">
          <div className="quote-container">
              <blockquote>
                  <p>Exceptional combination of Old World excellence and integrity. Thomas is always on time and within budget. No small matter when someone works in your home – he’s an easy presence.</p>
              </blockquote>  
              {/* <cite>
                <span>Kristi Bruno</span><br />
                  Social Media Specialist<br />
                  American College of Chest Physicians
              </cite> */}
          </div>
      </div>  
      <p className="text-center">
            <Link to="/services/energy-efficiency-installation/" className="btn btn-outline-dark">View All</Link>
          </p>  
    </Container>
    <Container>
      {/* <GatsbyImage image={getImage(data.light)} alt="The Desert" /> */}
    {/* <BgImage image={getImage(data.light)} className="subhead mb-3">
      <div className="color-overlay px-xs-0 px-sm-5 d-flex pt-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <Row>
                <Col lg={8}>
                  <p>To discuss your electrical needs</p>
                  <p>Please contact Master Electrician Thomas Tripolt Today!</p>
                </Col>
                <Col lg={4} className="d-flex justify-content-center align-items-center">
                  <p>
                    <Link to="/contact/" className="btn btn-light">Learn More</Link>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          </div>
          </BgImage> */}
    </Container>
    {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    /> */}
  </Layout>
)

export default IndexPage

export const query = graphql`
  query { 
    light: file(relativePath: {eq: "edison-bulbs-hanging.jpg"}) {
      relativePath
      base
      childImageSharp {
        gatsbyImageData(
          webpOptions: {quality: 50}
          blurredOptions: {width: 50, toFormat: WEBP}
          width: 2000
          formats: AUTO
          jpgOptions: {progressive: false, quality: 50}
        )
      }
    }
  }
`